<template>
    <fa-dialog
        :open="open"
        :fullscreen="mobile"
        :width="easyMode ? 500 : 1000"
        :height="mobile ? '100%' : easyMode ? '650px' : '800px'"
        icon="mdi-file-document-plus"
        @close="close"
        :title="null"
    >
        <template v-slot:header-buttons v-if="!easyMode">
            <v-row align="center">
                <v-col>
                    <v-btn
                        class="ml-2"
                        color="primary"
                        variant="flat"
                        @click="save"
                        size="small"
                        :disabled="
                            !(isBalanced && totalCredit > 0) ||
                            voucher.isSaving
                        "
                        :loading="voucher.isSaving"
                        submit
                    >
                        {{ $t("save-and-close") }}
                    </v-btn>
                </v-col>
                <v-col align="right">
                    <v-btn
                        v-if="!hasNewVoucher"
                        class="ml-2"
                        color="error"
                        variant="outlined"
                        @click="doDelete"
                        size="small"
                    >
                        {{ $t("delete") }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
        <template v-slot:main>
            <v-form ref="form" @submit.prevent="save">
                <v-row>
                    <v-col cols="auto" lg="4" order-lg="1">
                        <date-text-field
                            v-model="voucher.date"
                            ref="voucherDateTextField"
                            :label="$t('date')"
                            hideDetails
                        />
                    </v-col>
                    <v-col cols="12" order-lg="3">
                        <v-text-field
                            name="description"
                            :placeholder="$t('description')"
                            v-model="voucher.description"
                            class="text-h5 w-100"
                            :label="t('description')"
                            hide-details
                        />
                    </v-col>
                    <v-col v-if="projects.length > 0 || voucher.projectId !== null" cols="12" lg="8" order-lg="2">
                        <v-select
                            :items="projects"
                            item-value="id"
                            item-title="projectName"
                            v-model="voucher.projectId"
                            :label="t('project')"
                            clearable
                            hide-details
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-switch
                            name="easyRegistrationToggle"
                            :label="$t('simple-registration')"
                            :disabled="activeLines.length > 2"
                            v-model="easyMode"
                            hide-details
                            color="green"
                            inline
                        />
                    </v-col>
                </v-row>
                <v-row v-if="!easyMode" dense no-gutters>
                    <v-col cols="12" v-if="!mobile">
                        <v-btn
                            name="newLineButton"
                            v-if="!mobile"
                            prepend-icon="mdi-plus"
                            variant="outlined"
                            color="primary"
                            density="comfortable"
                            class="mb-3"
                            @click.prevent="addNewLine"
                        >
                            {{ $t("add") }}
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <voucher-lines-table
                            :lines="voucher.voucherLines"
                            :form-errors="formErrors"
                            ref="linesTable"
                        />
                    </v-col>
                </v-row>
                <!--EASY MODE-->
                <v-row v-if="easyMode">
                    <v-col cols="12">
                        <account-selector
                            name="easyModeCreditAccount"
                            v-model="easyModeCreditLine.accountId"
                            :label="userSettings.useSimpleLanguage ? $t('from-account') : $t('credit')"
                            :rules="[
                                (value) =>
                                    !!value || $t('required'),
                            ]"
                            hideDetails="auto"
                        />
                    </v-col>
                    <v-col cols="12">
                        <account-selector
                            name="easyModeDebitAccount"
                            v-model="
                                easyModeDebitLine.accountId
                            "
                            :label="userSettings.useSimpleLanguage ? $t('to-account') : $t('debit')"
                            :rules="[
                                (value) =>
                                    !!value || $t('required'),
                            ]"
                        />
                    </v-col>
                    <v-col cols="12">
                        <currency-text-field
                            name="easyModeAmount"
                            variant="outlined"
                            density="compact"
                            v-model="easyModeAmount"
                            :label="$t('amount')"
                            inputmode="decimal"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn
                            v-if="easyMode"
                            block
                            color="primary"
                            :disabled="
                                !(isBalanced && totalCredit > 0) ||
                                voucher.isSaving
                            "
                            :loading="voucher.isSaving"
                            @click.prevent="save"
                        >
                            {{ t('save-and-close') }}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-if="!hasNewVoucher && easyMode">
                    <v-col>
                        <v-btn
                            block
                            variant="text"
                            color="error"
                            :text="t('delete')"
                            @click.prevent="doDelete()"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template v-slot:footer>
            <v-toolbar
                color="main"
                v-if="!easyMode"
                :height="mobile ? 60 : 90"
            >
            <v-row class="ma-0" align="center" justify="center">
                    <v-col cols="12" md="6" v-if="!mobile">
                        <v-row dense no-gutters v-if="voucher.createdBy" class="text-body-2">
                            <v-col cols="12">
                                <p>{{ $t('created-by') }}:</p>
                                <p> {{voucher.createdBy.firstName }} {{ voucher.createdBy.lastName }} {{ $d(voucher.createdAt, 'long') }}</p>
                            </v-col>
                            <v-col cols="12">
                                <p>{{ $t('updated-by') }}:</p>
                                <p>{{ voucher.updatedBy.firstName }} {{ voucher.updatedBy.lastName }} {{ $d(voucher.updatedAt, 'long') }}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6" md="2" v-if="!mobile">
                        <v-text-field
                            name="totalCredit"
                            variant="solo"
                            density="compact"
                            readonly
                            :value="$n(totalCredit, 'currency', currency)"
                            v-model="totalDebit"
                            label="Total credit"
                            hide-details
                            tabindex="-1"
                        />
                    </v-col>
                    <v-col cols="6" md="2" v-if="!mobile">
                        <v-text-field
                            v-if="!mobile"
                            name="totalDebit"
                            variant="solo"
                            density="compact"
                            readonly
                            :value="$n(totalDebit, 'currency', currency)"
                            v-model="totalDebit"
                            label="Total debit"
                            hide-details
                            tabindex="-1"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                            variant="solo"
                            density="compact"
                            readonly
                            :value="$n(balanceDiff, 'currency', currency)"
                            v-model="balanceDiff"
                            :label="$t('difference')"
                            hide-details
                            tabindex="-1"
                            :error="balanceDiff > 0"
                        />
                    </v-col>
                </v-row>
            </v-toolbar>
        </template>
    </fa-dialog>
    <confirm-dialog ref="confirmDialog" />
</template>

<script setup lang="ts">
import { useDisplay } from 'vuetify';
import VoucherLinesTable from "./VoucherLinesTable.vue";
import AccountSelector from "/resources/js/components/account-selector/AccountSelector.vue";
import { useAccountingStore } from "@stores/accountingStore";
import ConfirmDialog from "/resources/js/components/ConfirmDialog.vue";
import { Voucher } from "../../../models/Voucher";
import { VoucherLine } from "../../../models/VoucherLine";
import { Ref } from 'vue';
import { ref } from "vue";
import { nextTick } from "vue";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from '../../../stores/authStore';
import { useProjectStore } from '../../../stores/projectStore';

const authStore = useAuthStore();
const projectStore = useProjectStore();
const userSettings = computed(() => authStore.user.userSettings);
const currency = computed(() => authStore.currentFamily.currency);

const voucher: Ref<Voucher|null> = ref(null);
const open: Ref<boolean> = ref(false);
const formErrors: Ref<{}> = ref({});
const easyMode: Ref<boolean> = ref(true);
const easyModeAmount: Ref<number> = ref(0);

const confirmDialog: ConfirmDialog = ref(null);

projectStore.getProjects();
const projects = computed(() => {
    let projects = projectStore.activeProjects;
    let index = projects.map(function(x) {return x.id; }).indexOf(voucher.value.projectId);

    if (index == -1) {
        let project = projectStore.projects.find((project) => project.id == voucher.value.projectId);

        if (project) {
            return [...projects, project];
        } else {
            return projects;
        }
    } else {
        return projects;
    }
});

const emits = defineEmits([
    "shouldClose",
    "saved",
    "deleted"
]);

const { t } = useI18n();

defineExpose({
    openVoucher,
    openNewVoucher
});

function openVoucher(voucherToOpen: Voucher) {
    voucher.value = voucherToOpen.copy();
    formErrors.value = {};

    if (voucher.value.voucherLines.length == 0) {
            addFreshLines();
        }
        if (voucher.value.voucherLines.length < 3) {
            easyMode.value = true;
        } else {
            easyMode.value = false;
        }

        if (easyMode) {
            easyModeAmount.value = easyModeCreditLine.value.credit ?? 0;
        }
        open.value = true;
}
const voucherDateTextField = ref(null);

function openNewVoucher() {
    voucher.value = new Voucher();
    addFreshLines();
    easyMode.value = true;
    easyModeAmount.value = 0;
    formErrors.value = {};
    open.value = true;
    nextTick().then(() => {

    })
    this.$nextTick(() => voucherDateTextField.value?.focus());
}

function addFreshLines() {
    let debitline = new VoucherLine();
    debitline.lineType = "debit";

    let creditline = new VoucherLine();
    creditline.lineType = "credit";


    voucher.value.voucherLines.push(creditline);
    voucher.value.voucherLines.push(debitline);
}

function close() {
    open.value = false;
}

const form = ref(null);

async function save() {
    const validForm = await form?.value.validate();
    if (validForm.valid == false) {
        return;
    }

    //Removing old form errors
    formErrors.value = {};

    // Saving the voucher
    for (const line of voucher.value.voucherLines) {
        if (easyMode.value) {
            easyModeCreditLine.value.credit = easyModeAmount.value;
            easyModeCreditLine.value.debit = 0;
            easyModeDebitLine.value.debit = easyModeAmount.value;
            easyModeDebitLine.value.credit = 0;
        }
        let credit = parseFloat(
            `${line.credit}`.replace(",", ".")
        ).toFixed(2);
        let debit = parseFloat(
            `${line.debit}`.replace(",", ".")
        ).toFixed(2);

        line.credit = Number(credit);
        line.debit = Number(debit);
    }
    voucher.value.save()
        .then((result: Voucher) => {
            voucher.value = result;
            emits("saved", result);
            let accountingStore = useAccountingStore();
            accountingStore.addVoucher(voucher.value);
            close();
        })
        .catch((error) => {
            console.error(error);
            open.value = true;
            console.error(error);
            formErrors.value = error.response.data.errors;
            open.value = true;
            confirmDialog.value
            .alert(
                t("error-during-save"),
                t("an-error-occured-during-saving")
            );
        });
}

function doDelete() {
    confirmDialog.value.okButtonColor = "error"
    confirmDialog.value.okButtonText = t('delete')
    confirmDialog.value
        .confirm(
            t("delete-voucher"),
            t("do-you-really-want-to-delete-this-voucher")
        )
        .then((confirmed) => {
            if (confirmed) {
                close();
                voucher.value.delete().then(() => {
                    accountingStore.removeVoucher(voucher.value);
                    emits("deleted", voucher.value);
                });
            }
        });
    return;
}

const linesTable: VoucherLinesTable = ref(null);

function addNewLine() {
    linesTable.value.addNew();
}

const accountingStore = useAccountingStore();

const totalCredit = computed(() => {
    if (easyMode.value) {
        return Number(`${easyModeAmount.value}`.replace(",", "."));
    }
    let total = Number(0);
    activeLines.value.forEach((line: VoucherLine) => {
        if (line.credit) {
            total += Number(`${line.credit}`.replace(",", "."));
        }
    });

    return total;
})

const totalDebit = computed(() => {
    if (easyMode.value) {
        return Number(`${easyModeAmount.value}`.replace(",", "."));
    }
    let total = Number(0);
    activeLines.value.forEach((line: VoucherLine) => {
        if (line.debit) {
            total += Number(`${line.debit}`.replace(",", "."));
        }
    });

    return total;
})

const balanceDiff = computed(() => {
    let credit = Number(totalCredit.value.toFixed(2));
    let debit = Number(totalDebit.value.toFixed(2));

    if (credit > debit) {
        return credit - debit;
    }

    return debit - credit;
})

const isBalanced = computed(() => {
    if (easyMode.value) {
        return true;
    }

    return totalCredit.value.toFixed(2) === totalDebit.value.toFixed(2);
})

const activeLines = computed(() => {
    return voucher.value.voucherLines.filter((line: VoucherLine) => line.deletedAt == null)
})

const easyModeCreditLine = computed(() => {
    if (voucher.value.voucherLines.length == 0) {
        addFreshLines();
    }
    let line = voucher.value.voucherLines.filter(
        (line) =>
            (line.deletedAt == null && line.credit > 0) ||
            line.lineType == "credit"
    );

    if (line[0]) {
        return line[0];
    }

    return voucher.value.voucherLines[0];
})

const easyModeDebitLine = computed(() => {
    if (voucher.value.voucherLines.length == 0) {
        addFreshLines();
    }
    let line = voucher.value.voucherLines.filter(
        (line) =>
            (line.deletedAt == null && line.debit > 0) ||
            line.lineType == "debit"
    );

    if (line[0]) {
        return line[0];
    }

    return voucher.value.voucherLines[1];
})

const { mobile } = useDisplay();

const hasNewVoucher = computed(() => {return voucher.value.id == null})

</script>

<style lang="scss" scoped>
.centered-input input {
    text-align: center;
    justify-content: center;
}

.centered-input {
    justify-self: center;
    align-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
}
</style>
