{
  "budget": "Budsjett | Budsjetter",
  "income": "Inntekt | Inntekter",
  "fixed-expense": "Fast utgift | Faste utgifter",
  "variable-expense": "Variabel utgift | Variable utgifter",
  "result": "Resultat | Resultater",
  "accounted-result": "Bokført resultat",
  "balance": "Saldo",
  "expenses": "Utgifter",
  "budget-result": "Budsjett-resultat",
  "choose-your-language": "Velg ditt språk",
  "choose-the-family-you-want-to-work-with": "Velg familien du ønsker å arbeide med",
  "new-family": "Ny familie",
  "accounts-in": "Kontoer i",
  "new": "Ny",
  "delete": "Slett",
  "accounted": "Bokført",
  "budget-categories": "Budsjett-kategorier",
  "edit": "Redigér",
  "new-category": "Ny kategori",
  "edit-category": "Redigér kategori",
  "create": "Opprett",
  "save": "Lagre",
  "category-name": "Kategorinavn",
  "description": "Beskrivelse",
  "name-is-required": "Navn er påkrevd",
  "name-must-be-less-than-255-characters": "Navnet må være mindre enn 255 tegn",
  "edit-account": "Redigér konto",
  "account-number": "Kontonummer",
  "name": "Navn",
  "statistics": "Statistikk",
  "welcome-to-factum": "Velkommen til Factum",
  "sign-in": "Logg inn",
  "oops-nothing-here": "Oops. Ingenting her...",
  "go-home": "Gå hjem",
  "home": "Hjem",
  "login-to-use-factum": "Logg inn for å bruke Factum",
  "email": "E-post",
  "password": "Passord",
  "keep-me-logged-in": "Hold meg innlogget",
  "forgot-password": "Glemt passord",
  "cancel": "Avbryt",
  "close-window": "Lukk vindu",
  "required": "Påkrevd",
  "enter-your-e-mail-to-reset-your-password": "Skriv inn din e-post adresse for å resette passordet",
  "invalid-username-or-password": "Ugyldig brukernavn eller passord",
  "your-user-account-was-created-successfully": "Din brukerkonto ble opprettet",
  "account-type": "Konto-type",
  "email-verified": "E-post bekreftet",
  "account-created": "Konto opprettet",
  "create-your-factum-account": "Opprett din Factum-konto",
  "this-email-is-already-registered": "Denne e-post adressen er allerede registrert",
  "you-can-now-log-in": "Du kan nå logge inn",
  "check-your-inbox-to-continue!": "Sjekk innboksen din for å fortsette!",
  "create-account": "Opprett konto",
  "sign-up-for-your-free-factum-account-now": "Registrer deg gratis nå",
  "first-name": "Fornavn",
  "last-name": "Etternavn",
  "birthdate": "Fødselsdato",
  "repeat-password": "Gjenta passord",
  "register": "Registrer",
  "the-passwords-does-not-match": "Passordene samsvarer ikke",
  "change-password": "Endre passord",
  "reset-your-password": "Endre passordet ditt",
  "an-error-occured-when-changing-the-password": "Det oppstod en feil under endring av passordet",
  "sendt": "Sendt",
  "you-must-verify-your-email": "Du må bekrefte e-post adressen din",
  "resend-email": "Send e-post på nytt",
  "logout": "Logg ut",
  "invitations-to-families": "Invitasjoner til familier",
  "you-got-invited-by": "Du ble invitert av",
  "role": "Rolle",
  "accept": "Godta",
  "decline": "Avslå",
  "families": "Familier",
  "inventory": "Inventar",
  "calendar": "Kalender",
  "more": "Mer",
  "my-profile": "Min profil",
  "add-profile-picture": "Legg til profilbilde",
  "language": "Språk",
  "norwegian": "Norsk",
  "english": "Engelsk",
  "profile-image": "Profilbilde",
  "personal-information": "Personlig informasjon",
  "my-families": "Mine familier",
  "saving": "Sparekonto",
  "add": "Legg til",
  "from-date": "Fra dato",
  "to-date": "Til dato",
  "recurrency": "Gjentakelse",
  "amount": "Beløp",
  "actions": "Handlinger",
  "repeat-every": "Gjenta hver",
  "months": "Måned | Måneder",
  "years": "År",
  "new-account": "Ny konto",
  "save-and-close": "Lagre og lukk",
  "category": "Kategori",
  "difference": "Differanse",
  "forecast": "Prognose",
  "fill": "Fyll",
  "date": "Dato",
  "action": "Handling",
  "subtract": "Trekk fra",
  "set-to": "Sett til",
  "events": "Hendelser",
  "top-expenses": "Topp utgifter",
  "others": "Andre",
  "expense": "Utgift",
  "accounting": "Regnskap",
  "vouchers": "Bilag",
  "search": "Søk",
  "vouchers-per-page": "Bilag pr. side",
  "new-voucher": "Nytt bilag",
  "edit-voucher": "Redigér bilag",
  "drop-files-here": "Slipp filer her",
  "drag-n-drop": "Dra & Slipp",
  "or-select-files": "eller velg filer",
  "no-files": "Ingen filer",
  "account": "Konto",
  "note": "Notat",
  "credit": "Kredit",
  "debit": "Debet",
  "invalid-account-number": "Ugyldig kontonummer",
  "money": "Penger",
  "bank-account": "Bankkonto",
  "cash": "Kontanter",
  "savings-account": "Sparekonto",
  "initial-balance": "Inngående saldo",
  "liquidity-forecast": "Likviditetsprognose",
  "money-accounts": "Pengekontoer",
  "no-money-accounts": "Ingen pengekontoer",
  "create-one-now": "Opprett en nå",
  "transactions": "Transaksjoner",
  "no-transactions-matches-the-filter": "Ingen transaksjoner matcher filteret",
  "every": "hver",
  "family-name": "Familienavn",
  "create-a-family": "Opprett en familie",
  "create-a-new-family-and-start-from-scratch": "Opprett en ny familie og start med blanke ark",
  "you-are-not-member-of-any-families": "Du er ikke medlem av noen familier",
  "you-need-to-be-member-of-a-family-in-order-to-use-factum": "Du må være medlem av en familie for å bruke Factum",
  "you-have-two-options": "Du har to alternativer",
  "wait-for-an-invitation": "Vent på en invitasjon",
  "have-someone-else-invite-you-to-their-family": "Få noen andre til å invitere deg til sin familie",
  "others-can-invite-you-by-your-email": "Andre kan invitere deg med din e-post adresse",
  "when-someone-invites you-the-invitation-will-show-up-here": "Når noen har invitert deg, vil invitasjonen vises her",
  "you-are-logged-in-as": "Du er logget inn som",
  "you-need-to-wait-a-bit-before-you-can-do-this-again": "Du må vente litt før du kan gjøre dette igjen",
  "user": "Bruker",
  "reader": "Leser",
  "owner": "Eier",
  "edit-family": "Redigér familie",
  "members": "Medlemmer",
  "remove": "Fjern",
  "invite-someone": "Invitér noen",
  "send-invitation": "Send invitasjon",
  "the-invitation-was-sendt": "Invitasjonen ble sendt",
  "invited-by": "Invitert av",
  "simple-registration": "Enkel registrering",
  "current-money-balance": "Gjeldende penge-saldo",
  "upcoming-events": "Kommende hendelser",
  "in": "I",
  "budget-increased-by": "Budsjettet økt med",
  "budget-decreased-by": "Budsjettet redusert med",
  "budget-changed-to": "Budsjettet endret til",
  "ok": "Ok",
  "delete-voucher": "Slette bilag",
  "do-you-really-want-to-delete-this-voucher": "Er du sikker på at du vil slette dette bilaget?",
  "delete-account": "Slette konto",
  "do-you-really-want-to-delete-this-account": "Er du sikker på at du vil slette denne kontoen?",
  "january": "Januar",
  "february": "Februar",
  "march": "Mars",
  "april": "April",
  "may": "Mai",
  "june": "Juni",
  "july": "Juli",
  "august": "August",
  "september": "September",
  "october": "Oktober",
  "november": "November",
  "december": "Desember",
  "select-period": "Velg periode",
  "this-affects-many-parts-of-the-system": "Dette påvirker store deler av systemet",
  "dark": "Mørkt",
  "no-category-selected": "Ingen kategori valgt",
  "no-vouchers-matches-the-filter": "Ingen bilag samsvarer med filteret",
  "filter": "Filter",
  "each": "hver",
  "MONTH": "Måned",
  "YEAR": "År",
  "from": "Fra",
  "budgets": "Budsjetter",
  "remaining": "Gjenstående",
  "budget-account": "Budsjettkonto",
  "event": "Hendelse",
  "back": "Tilbake",
  "to-be-placed": "Å fordele",
  "recurring-budgets": "Gjentakende budsjetter",
  "recurring-budgets-set-a-fixed-amount-for-the-account-within-a-specific-interval": "Gjentakende budsjetter setter et fast beløp for kontoen i et gitt intervall",
  "this-can-be-overridden-by-using-events": "Dette kan overstyres ved å bruke hendelser",
  "month": "måned",
  "current": "Gjeldende",
  "edit-budget": "Redigér budsjett",
  "budget-events": "Budsjett-hendelser",
  "budget-events-can-modify-a-recurring-budget-when-something-occurs": "Budsjetthendelser kan endre et gjentakende budsjett når noe spesielt hender",
  "edit-budget-event": "Redigér budsjetthendelse",
  "new-budget": "Nytt budsjett",
  "no-budgets-for-this-account": "Ingen budsjetter for denne kontoen",
  "no-budget-events-for-this-account": "Ingen budsjett-hendelser for denne kontoen",
  "shows-a-list-of-vouchers-within-a-period-of-time": "Viser en liste over bilag innenfor en gitt tidsramme",
  "no-accounts": "Ingen kontoer",
  "shows-the-usage-in-this-account-over-time": "Viser forbruket for denne kontoen over tid",
  "no-data-to-show": "Ingen data å vise",
  "created-by": "Opprettet av",
  "updated-by": "Endret av",
  "error-during-save": "Feil under lagring",
  "an-error-occured-during-saving": "Det oppstod en feil under lagring",
  "no-budget-events-this-month": "Ingen budsjetthendelser denne måneden",
  "no-persons": "Ingen personer",
  "select-icon": "Velg ikon",
  "apple": "Apple",
  "software": "Programvare",
  "select-an-icon": "Velg et ikon",
  "remove-icon": "Fjern ikon",
  "settings": "Innstillinger",
  "notifications": "Varslinger",
  "use-simple-language": "Bruk enkelt språk",
  "from-account": "Fra konto",
  "to-account": "Til konto",
  "push": "Push",
  "daily-accounting-reminder": "Daglig påminning om bokføring",
  "budget-exceeded": "Budsjett overskredet",
  "voucher-created": "Bilag opprettet",
  "users": "Brukere",
  "invitations": "Invitasjoner",
  "persons": "Personer",
  "make-changes-to-your-family": "Gjør endringer i din familie",
  "family": "Familie",
  "manage-the-users-that-can-access-this-family": "Håndter brukerne som har tilgang til denne familien",
  "guest": "Gjest",
  "remove-user": "Fjern bruker",
  "saved-changes": "Endringene ble lagret",
  "admin": "Admin",
  "do-you-really-want-to-remove-this-user": "Er du sikker på at du vil fjerne brukeren fra familien? Brukeren vil miste tilgang umiddelbart.",
  "the-user-was-successfully-removed": "Brukeren ble fjernet fra familien",
  "invite-someone-to-this-family-or-edit-already-sent-invitations": "Inviter noen til denne familien, eller endre invitasjoner som allerede er sendt",
  "invite-someone-using-their-email-address": "Inviter personer ved å bruke vedkommendes e-post adresse",
  "delete-invitation": "Slett invitasjon",
  "the-invitation-was-deleted": "Invitasjonen ble slettet",
  "do-you-really-want-to-delete-this-invitation": "Er du sikker på at du vil slette denne invitasjonen",
  "the-invitation-was-sent": "Invitasjonen ble sendt",
  "send": "Send",
  "invalid-e-mail": "Ugyldig e-post adresse",
  "a-list-of-persons-in-the-family": "En liste over personer som inngår i familien",
  "this-information-is-used-to-calculate-budgets-and-show-statistics-of-income-and-expenses": "Denne informasjonen brukes for å beregne budsjetter, og til å vise statistikk over inntekter og utgifter",
  "add-person": "Legg til person",
  "birth-year": "Fødselsår",
  "gender": "Kjønn",
  "male": "Mann",
  "female": "Kvinne",
  "create-a-new-family-now": "Opprett en ny familie nå",
  "add-family": "Ny familie",
  "reading-mode": "Lese-modus",
  "you-dont-have-access-to-perform-any-changes-to-this-family": "Du har ikke tilgang til å utføre noen endringer på denne familien",
  "server-error": "Serverfeil",
  "missing-anything": "Savner du noe?",
  "developing-a-great-app-is-a-continous-job": "Å utvikle en bra app er en kontinuerlig jobb",
  "we-would-love-to-get-your-ideas": "Vi hadde elsket å høre idéene dine",
  "write-us-something": "Skriv noe til oss",
  "missing-an-icon-or-maybe-you-just-want-to-say-hi": "Savner du et ikon? Eller kanskje vil du bare si hei?",
  "we-appreciate-every-contact-we-get": "Vi blir glade for alle henvendelser vi får",
  "thank-you": "Tusen takk",
  "your-message-was-sent": "Meldingen din ble sendt",
  "we-will-reply-to-your-registered-email-as-soon-as-we-can": "Vi svarer deg på din registrerte e-post adresse så snart som mulig",
  "welcome": "Velkommen",
  "norway": "Norge",
  "sweden": "Sverige",
  "norwegian-krone": "Norske kroner",
  "swedish-krone": "Svenske kroner",
  "country": "Land",
  "currency": "Valuta",
  "next": "Neste",
  "car-expenses": "Bilutgifter",
  "kindergarden": "Barnehage",
  "tobacco": "Tobakk",
  "car-loan": "Billån",
  "rent": "Husleie",
  "mortgage": "Boliglån",
  "kids-expenses": "Utgifter til barn",
  "pets-expenses": "Utgifter til kjæledyr",
  "super-admin": "Super-admin",
  "tools-for-managing-factum": "Verktøy for å administrere Factum",
  "feedbacks": "Tilbakemeldinger",
  "activated": "Aktivert",
  "no-users-matching-the-filter": "Ingen brukere matcher filteret",
  "created": "Opprettet",
  "updated": "Oppdatert",
  "family-count": "Antall familier",
  "last-seen": "Sist sett",
  "never": "Aldri",
  "last-activity": "Siste aktivitet",
  "no-families": "Ingen familier",
  "budget-accounts": "Budsjettkontoer",
  "this-family-has-not-completed-setup": "Denne familien har ikke fullført oppstartsveiviseren",
  "no-feedbacks-matching-the-filter": "Ingen tilbakemeldinger matcher filteret",
  "message": "Melding",
  "mark-as-resolved": "Merk som løst",
  "write-to-us": "Skriv til oss",
  "email-was-sent": "E-posten ble sendt",
  "resend-verification-email": "Send bekreftelses e-post på nytt",
  "disable-user": "Deaktiver bruker",
  "this-user-is-deactivated": "Denne brukeren er deaktivert",
  "disabled-by": "Deaktivert av",
  "enable": "Aktiver",
  "the-user-was-disabled": "Brukeren ble deaktivert",
  "the-user-was-enabled": "Brukeren ble aktivert",
  "your-account-is-disabled": "Din konto er deaktivert",
  "push-notifications": "Push-varsler",
  "delete-family": "Slett familie",
  "deleting-this-family-can-not-be-undone": "Å slette denne familien kan ikke angres",
  "all-data-will-be-permanently-destroyed": "All data vil bli permanent slettet",
  "type-the-excact-family-name-to-confirm-that-you-really-want-to-delete-this-family-forever": "Skriv inn det nøyaktige familienavnet for å bekrefte at du virkelig ønsker å slette denne familien for alltid",
  "permanently-delete-family": "Slett familie permanent",
  "switched-to": "Byttet til",
  "leave-family": "Forlat familien",
  "do-you-really-want-to-leave-this-family": "Er du sikker på at du vil forlate denne familien?",
  "leave": "Forlat",
  "invalid-first-name": "Ugyldig fornavn",
  "invalid-last-name": "Ugyldig etternavn",
  "invalid-password": "Ugyldig passord",
  "creating-an-account-is-free-and-without-any-strings-attached": "Å opprette en konto er gratis, uten at du binder deg til noe.",
  "you-can-delete-the-account-at-any-point": "Du kan slette kontoen når som helst.",
  "creating-account": "Oppretter konto",
  "password-requirements": "Krav til passord",
  "min-8-chars-one-number-and-one-special-char": "Min. 8 tegn, inkludert minst 1 tall og 1 spesialtegn",
  "related": "Relasjoner",
  "relations": "Relasjoner",
  "danger-zone": "Fare-sone",
  "this-user-has-not-confirmed-its-email-address-and-is-not-yet-activated": "Denne brukeren har ikke bekreftet e-post adressen sin, og er derfor ikke aktivert enda",
  "logging-in": "Logger inn...",
  "notes": "Notater",
  "title": "Tittel",
  "author": "Forfatter",
  "no-notes": "Ingen notater",
  "new-note": "Nytt notat",
  "delete-note": "Slett notat",
  "do-you-really-want-to-delete-this-note": "Er du sikker på at du ønsker å slette dette notatet?",
  "os": "OS",
  "agent": "Agent",
  "mobile": "Mobile",
  "device": "Enhet",
  "login-date": "Pålogging",
  "no-devices": "Ingen enheter",
  "devices": "Enheter",
  "hello": "Hei",
  "active-users-right-now": "Aktive brukere akkurat nå",
  "count": "Antall",
  "platforms": "Plattformer",
  "new-users": "Nye brukere",
  "last-24-hours": "Siste 24 timer",
  "active-users": "Aktive brukere",
  "right-now": "Akkurat nå",
  "users-count": "Antall brukere",
  "total": "Totalt",
  "result-for": "Resultat for",
  "shows-how-your-money-balance-evolves": "Viser hvordan pengene dine utvikler seg over tid",
  "shows-how-your-expenses-are-distributed": "Viser hvordan utgiftene fordeler seg",
  "showing": "Viser",
  "we-could-not-find-the-page-you-are-looking-for": "Vi kunne ikke finne siden du leter etter",
  "go-back": "Gå tilbake",
  "shows-a-list-of-your-planned-events-for-the-selected-period": "Viser en liste over planlagte hendelser",
  "no-accounts-in-this-category": "Ingen kontoer i denne kategorien",
  "categories": "Kategorier",
  "this-budget-is-empty": "Dette budsjettet er tomt",
  "start-by-adding-some-categories": "Start med å legge til noen kategorier",
  "this-category-does-not-have-any-accounts": "Denne kategorien har ingen kontoer",
  "add-accounts-to-set-up-a-budget-with-numbers": "Legg til kontoer for å sette opp et budsjett med tall",
  "no-data-to-display": "Ingen data å vise",
  "setup-a-budget-to-show-this-chart": "Sett opp et budsjett for å vise dette diagrammet",
  "other-accounts": "Andre kontoer",
  "my-accounts": "Mine kontoer",
  "budget-gap": "Budsjettsprekk",
  "overconsumption": "Overforbruk",
  "no-overconsumption-this-period": "Ingen overforbruk denne perioden",
  "gap": "Sprekk",
  "liquidity": "Likviditet",
  "how-long-will-your-money-last": "Hvor lenge vil pengene vare",
  "liquidity-is-a-measure-of-how-solid-your-economy-is": "Likviditet er et mål på hvor solid økonomien din er",
  "the-higher-the-score-the-longer-your-money-will-last": "Jo høyere score, jo lengre vil pengene dine vare",
  "a-score-of-2-is-considered-good-while-a-score-above-1-is-considered-okay": "En score på 2 er ansett som bra, mens en score over 1 er ansett som ok",
  "the-score-is-based-on-fixed-expenses-and-your-total-money-balance": "Scoren er basert på faste utgifter og den totale pengebeholdningen",
  "how-is-the-score-calculated": "Hvordan er scoren beregnet?",
  "the-math-behind-is-easy": "Matten bak er enkel",
  "your-money-divided-by-your-fixed-expenses": "Pengene dine delt på de faste utgiftene dine",
  "score": "Score",
  "what-can-this-tell-you": "Hva forteller dette",
  "how-long-your-money-will-last-if-you-lost-all-your-income": "Hvor lenge pengene dine varer dersom du mister all inntekt",
  "how-able-you-are-to-pay-your-expenses": "Hvor stor mulighet du har til å betale dine utgifter",
  "banks-use-this-score-to-determine-if-they-will-grant-loans": "Banker bruker denne scoren til å avgjøre om de innvilger lån",
  "liquidity-is-a-measure-of-how-able-you-are-to-pay-your-expenses": "Likviditet er et mål på hvor stor mulighet du har til å betjene utgiftene dine",
  "overconsumption-is-the-sum-of-accounted-amounts-for-accounts-where-you-have-spent-more-money-than-the-budget-allows": "Overforbruk er summen av beløper som overstiger budsjettet for utgiftskontoer",
  "when-your-fiexed-expenses-are-0-the-score-is-set-to-2-as-default": "Dersom de faste utgiftene er 0, setter vi scoren til 2 som standard",
  "exclude-from-balance": "Ekskluder fra saldo",
  "when-an-account-is-excluded-from-balance-its-balance-will-not-be-included-in-the-total-balance-in-charts-or-in-liquidity": "Når en konto er ekskludert fra saldo, vil beløpet ikke inngå i din pengesaldo, i grafer over utvikling av penger eller i beregning av likviditet",
  "average": "Gjennomsnitt",
  "average-accounted": "Gjennomsnitt bokført",
  "value": "Verdi",
  "months-with-overconsumption": "Måneder med overforbruk",
  "percent": "Prosent",
  "average-overconsumption": "Gjennomsnitt overforbruk",
  "new-budget-item": "Ny budsjettpost",
  "loading": "Laster inn",
  "projects": "Prosjekter",
  "add-project": "Nytt prosjekt",
  "no-projects": "Ingen prosjekter",
  "create-your-first-project": "Opprett ditt første prosjekt",
  "project": "Prosjekt",
  "start-date": "Startdato",
  "end-date": "Sluttdato",
  "close": "Lukk",
  "new-project": "Nytt prosjekt",
  "active": "Aktive",
  "finished": "Fullførte",
  "completed": "Fullført",
  "no-vouchers": "Ingen bilag",
  "add-voucher": "Opprett bilag",
  "total-income": "Totalt inntekter",
  "total-expenses": "Totalt utgifter",
  "project-result": "Prosjektresultat",
  "add-line": "Legg til linje",
  "voucher": "Bilag",
  "creating-an-account-is-100-percent-free": "Å opprette en konto er 100% gratis",
  "vouchers-are-transactions-in-your-accounting": "Bilag er transaksjoner i regnskapet ditt",
  "a-transaction-moves-money-from-one-account-to-another": "En transaksjon flytter penger fra en konto til en annen",
  "add-account": "Ny konto",
  "a-money-account-is-where-you-keep-your-money": "En pengekonto er stedet du har pengene dine",
  "my-account": "Min konto",
  "no-income": "Ingen inntekt",
  "no-expenses": "Ingen utgifter",
  "we-have-sent-you-an-email-with-instructions-on-how-to-confirm-your-email-address": "Vi har sendt deg en e-post med instruksjoner om hvordan du bekrefter e-post adressen din",
  "if-you-did-not-receive-it-you-can-resend-it-using-the-button-below": "Dersom du ikke har fått denne, kan du få en ny ved å trykke på knappen under",
  "sent": "Sendt",
  "get-started": "Kom i gang",
  "spend-some-minutes-getting-your-new-factum-family-ready": "Bruk noen minutter på å komme i gang med din nye Factum-familie",
  "general-settings": "Generelle innstillinger",
  "tell-us-where-your-family-is-located-and-which-currency-to-use": "Fortell oss hvor familien din er lokalisert, og hvilken valuta du ønsker å bruke",
  "family-members": "Familiemedlemmer",
  "to-be-able-to-compare-you-to-other-families-we-need-to-know-a-little-about-who-your-family-is": "For at vi skal kunne gi deg en sammenligning med andre familier, trenger vi å vite litt om hvem som er i din familie",
  "right-now-your-budget-is-blank": "Foreløpig er budsjettet ditt tomt",
  "do-you-want-us-to-setup-a-default-budget-for-you": "Ønsker du at vi setter opp et standardbudsjett for deg",
  "you-can-edit-this-later": "Du kan redigere dette senere",
  "ordinary-budget": "Vanlig budsjett",
  "includes-groceries-but-does-not-split-between-food-and-other-household-articles": "Inkluderer for eksempel dagligvarer, men skiller ikke mellom mat og husholdningsartikler",
  "detailed-budget": "Detaljert budsjett",
  "empty": "Tomt",
  "start-fresh-and-build-your-own-budget": "Start med blanke ark og bygg ditt eget budsjett",
  "other-expenses": "Andre utgifter",
  "check-the-boxes-corresponding-to-the-expenses-your-family-has": "Kryss av i boksene for hvilke andre utgifter din familie har",
  "splits-between-food-and-cleaning-items": "Skiller mellom mat og rengjøringsartikler",
  "add-your-money-accounts-with-todays-balance-to-let-us-show-you-how-your-money-develops-with-time": "Legg til pengekontoene dine med dagens saldo slik at vi kan vise deg hvordan pengene dine utvikler seg over tid",
  "complete": "Fullfør",
  "press-complete-to-start-the-installation": "Trykk på fullfør for å starte installeringen",
  "we-have-all-the-information-we-need": "Vi har all informasjonen vi trenger",
  "this-wizard-will-close-automatically-when-the-configuration-is-completed": "Denne veiviseren vil lukkes automatisk når konfigurasjonen er ferdig",
  "this-might-take-a-while": "Dette kan ta litt tid...",
  "creating-persons": "Oppretter personer",
  "creating-money-accounts": "Oppretter pengekontoer",
  "creating-budget": "Oppretter budsjett",
  "you-are": "Du er",
  "euro": "Euro",
  "united-states-dollar": "Amerikanske dollar",
  "too-many-attempts-try-again-later": "For mange forsøk. Prøv på nytt senere",
  "verify-your-email": "Bekreft e-post adressen din",
  "become-a-family-member": "Bli medlem av en familie",
  "we-would-love-to-hear-from-you": "Vi vil gjerne høre fra deg",
  "projects-are-a-great-way-to-get-an-overview-of-income-or-expenses-related-to-an-event-or-a-project": "Prosjekter er en super måte å få oversikt over inntekter eller utgifter i forbindelse med en hendelse eller et prosjekt",
  "you-can-set-goals-for-how-much-you-want-to-earn-spend-or-save": "Du kan sette mål for hvor mye du vil tjene, bruke eller spare",
  "swedish": "Svensk",
  "thats-it": "Det var alt"
}
