<template>
    <fa-dialog
        :open="open"
        :fullscreen="mobile"
        :title="hasNewFamily ? $t('new-family') : family?.familyName"
        :width="mobile ? '100%' : 800"
        :height="mobile ? '100%' : 800"
        :isLoading="loading"
        icon="mdi-account-group"
        @close="open = false"
    >
        <template v-slot:tabs v-if="!hasNewFamily">
                <v-btn-toggle
                    v-model="currentView"
                    rounded="0"
                    color="primary"
                    group
                    mandatory
                    align="center"
                >
                    <v-btn value="main">
                        <v-icon>mdi-home</v-icon>
                        <span class="hidden-sm-and-down">{{ $t('home') }}</span>
                    </v-btn>
                    <v-btn value="users">
                        <v-icon>mdi-account-multiple</v-icon>
                        <span class="hidden-sm-and-down">{{ $t('users') }}</span>
                    </v-btn>

                    <v-btn value="invitations">
                        <v-icon>mdi-account-multiple-plus</v-icon>
                        <span class="hidden-sm-and-down">{{ $t('invitations') }}</span>
                    </v-btn>

                    <v-btn value="persons">
                        <v-icon>mdi-account-group</v-icon>
                        <span class="hidden-sm-and-down">{{ $t('persons') }}</span>
                    </v-btn>
                    <v-btn value="destroy">
                        <v-icon color="error">mdi-close-octagon</v-icon>
                        <span class="hidden-sm-and-down text-error">{{ $t('danger-zone') }}</span>
                    </v-btn>
                </v-btn-toggle>
                <v-alert
                    v-if="!canEdit"
                    density="compact"
                    type="info"
                    :title="$t('reading-mode')"
                    closable
                >
                <p class="text-left">{{ $t('you-dont-have-access-to-perform-any-changes-to-this-family') }}</p>
                </v-alert>
        </template>
        <template v-slot:main v-if="currentView == 'main'">
            <v-row align="center">
                <v-col cols="2" md="1">
                    <v-icon size="x-large">mdi-home</v-icon>
                </v-col>
                <v-col v-if="!hasNewFamily">
                    <p class="text-subtitle-1">{{ $t('family') }}</p>
                    <p class="text-body-2">{{ $t('make-changes-to-your-family') }}.</p>
                </v-col>
                <v-col v-if="hasNewFamily">
                    <p class="text-subtitle-1">{{ $t('new-family') }}</p>
                    <p class="text-body-2">{{ $t('create-a-new-family-now') }}.</p>
                </v-col>
                <v-divider />
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        v-model="family.familyName"
                        :label="$t('family-name')"
                        :disabled="!canEdit"
                    />
                </v-col>
            </v-row>
            <v-row class="mt-2">
                <v-col cols="12">
                    <v-select
                        v-model="family.country"
                        :items="countries"
                        item-title="title"
                        item-value="value"
                        :label="$t('country')"
                        :rules="[v => !!v || $t('required')]"
                    />
                </v-col>
                <v-col cols="12">
                    <v-select
                        v-model="family.currency"
                        :items="currencies"
                        item-title="title"
                        item-value="value"
                        :label="$t('currency')"
                        :rules="[v => !!v || $t('required')]"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn
                        variant="flat"
                        color="success"
                        @click.stop="saveFamily()"
                        :disabled="family.isSaving || !canEdit"
                        :loading="family.isSaving"
                        block
                    >
                        {{ hasNewFamily ? $t('create') : $t('save') }}
                    </v-btn>
                </v-col>
            </v-row>
        </template>
        <template v-slot:main v-if="currentView == 'users'">
            <v-row align="center">
                <v-col cols="2" md="1">
                    <v-icon size="x-large">mdi-account-multiple</v-icon>
                </v-col>
                <v-col>
                    <p class="text-subtitle-1">{{ $t('users') }}</p>
                    <p class="text-body-2">{{ $t('manage-the-users-that-can-access-this-family') }}.</p>
                </v-col>
                <v-divider />
            </v-row>
            <div
                v-for="membership in family.memberships"
                :key="membership.id"
                class="mt-2"
            >
                <v-row justify="center" align="center">
                    <v-col cols="12" md="5" class="d-flex flex-row align-center">
                        <v-avatar
                            color="main"
                        >
                            <v-img v-if="membership.user.profilePicture" :src="membership.user.profilePicture">
                            </v-img>
                            <span v-else>
                                {{ membership.user.initials }}
                            </span>
                        </v-avatar>
                        <p class="ml-3">{{ membership?.user?.fullName }}</p>
                    </v-col>
                    <v-col cols="6" md="4">
                        <v-select
                            :items="roles"
                            v-model="membership.role"
                            :label="$t('role')"
                            item-value="role"
                            item-title="title"
                            density="compact"
                            hide-details
                            @update:model-value="saveMembership(membership)"
                            :disabled="!canEdit"
                        />
                    </v-col>
                    <v-col cols="6" md="3">
                        <v-btn
                            color="error"
                            style="flat"
                            @click.stop="deleteMembership(membership)"
                            :disabled="!canEdit"
                        >
                            {{ $t('remove-user') }}
                        </v-btn>
                    </v-col>
                    <v-divider />
                </v-row>
            </div>
        </template>
        <template v-slot:main v-if="currentView == 'invitations'">
            <v-row align="center">
                <v-col cols="2" md="1">
                    <v-icon size="x-large">mdi-account-multiple-plus</v-icon>
                </v-col>
                <v-col>
                    <p class="text-subtitle-1">{{ $t('invitations') }}</p>
                    <p class="text-body-2">{{ $t('invite-someone-to-this-family-or-edit-already-sent-invitations') }}.</p>
                </v-col>
                <v-divider />
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card
                        prepend-icon="mdi-mail"
                        :title="$t('invite-someone')"
                        :subtitle="$t('invite-someone-using-their-email-address')"
                        variant="outlined"
                    >
                        <v-card-text>
                            <v-row :no-gutters="mobile">
                                <v-col cols="12" md="10">
                                    <v-form ref="newInvitationForm" validate-on="blur">
                                        <v-text-field
                                            :label="$t('email')"
                                            :rules="[Rules.Email]"
                                            v-model="inviteEmail"
                                            :disabled="!canEdit"
                                            inputmode="email"
                                        />
                                    </v-form>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-btn
                                        color="success"
                                        block
                                        :disabled="inviteEmail.length < 5"
                                        @click.stop="sendInvitation()"
                                    >
                                        {{ $t('send') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div
                        v-for="invitation in family.invitations"
                        :key="invitation.id"
                        class="mt-2"
                    >
                        <v-row align="center">
                            <v-col cols="12" md="6" class="d-flex">
                                <v-icon color="warning">mdi-circle-medium</v-icon>
                                <p>{{ invitation.email }}</p>
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-select
                                    :items="roles"
                                    v-model="invitation.role"
                                    :label="$t('role')"
                                    item-value="role"
                                    item-title="title"
                                    density="compact"
                                    hide-details
                                    @update:model-value="saveInvitation(invitation)"
                                />
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-btn
                                    color="error"
                                    style="flat"
                                    @click.stop="deleteInvitation(invitation)"

                                >
                                    {{ $t('delete-invitation') }}
                                </v-btn>
                            </v-col>
                            <v-divider />
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </template>
        <template v-slot:main v-if="currentView == 'persons'">
            <v-row align="center">
                <v-col cols="2" md="1">
                    <v-icon size="x-large">mdi-account-group</v-icon>
                </v-col>
                <v-col>
                    <p class="text-subtitle-1">{{ $t('persons') }}</p>
                    <p class="text-body-2">{{ $t('a-list-of-persons-in-the-family') }}.</p>
                    <p class="text-body-2">{{ $t('this-information-is-used-to-calculate-budgets-and-show-statistics-of-income-and-expenses') }}.</p>
                </v-col>
                <v-divider />
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card
                        prepend-icon="mdi-account-plus"
                        :title="$t('add-person')"
                        variant="outlined"
                        :disabled="!canEdit"
                    >
                        <v-card-text>
                            <v-form ref="newPersonForm" validate-on="blur">
                                <v-row dense>
                                    <v-col cols="12" md="8">
                                        <v-text-field
                                            :label="$t('name')"
                                            v-model="newPerson.name"
                                        />
                                    </v-col>
                                    <v-col cols="6" md="2">
                                        <v-text-field
                                            :label="$t('birth-year')"
                                            type="number"
                                            v-model="newPerson.birthYear"
                                        />
                                    </v-col>
                                    <v-col cols="6" md="2">
                                        <v-select
                                            :label="$t('gender')"
                                            :items="genders"
                                            item-value="gender"
                                            item-title="title"
                                            v-model="newPerson.gender"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-btn
                                            color="success"
                                            block
                                            @click.stop="saveNewPerson()"
                                        >
                                            {{ $t('add') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div
                        v-for="person in family.persons"
                        :key="person.id"
                        class="mt-2"
                    >
                    <v-row dense>
                        <v-col cols="12" md="6">
                            <v-text-field
                                :label="$t('name')"
                                v-model="person.name"
                                :disabled="!canEdit"
                            />
                        </v-col>
                        <v-col cols="6" md="2">
                            <v-text-field
                                :label="$t('birth-year')"
                                type="number"
                                v-model="person.birthYear"
                                :disabled="!canEdit"
                            />
                        </v-col>
                        <v-col cols="6" md="2">
                            <v-select
                                :label="$t('gender')"
                                :items="genders"
                                item-value="gender"
                                item-title="title"
                                v-model="person.gender"
                                :disabled="!canEdit"
                            />
                        </v-col>
                        <v-col cols="6" md="1">
                            <v-btn
                                color="success"
                                block
                                @click.stop="savePerson(person)"
                                :disabled="!canEdit"
                            >
                                <v-icon>mdi-content-save</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="6" md="1">
                            <v-btn
                                color="error"
                                block
                                @click.stop="deletePerson(person)"
                                :disabled="!canEdit"
                            >
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                        <v-divider class="mb-4 mt-4"/>
                    </v-row>
                    </div>
                </v-col>
            </v-row>
        </template>
        <template v-slot:main v-if="currentView == 'destroy'">
            <div v-if="canEdit">
                <v-row>
                    <v-col class="">
                        <v-alert type="warning">
                            {{ $t('deleting-this-family-can-not-be-undone') }}. {{ $t('all-data-will-be-permanently-destroyed') }}.
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        {{ $t('type-the-excact-family-name-to-confirm-that-you-really-want-to-delete-this-family-forever') }}: ({{ family.familyName }})
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="deleteConfirmationFamilyName"
                            :label="$t('family-name')"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn
                            color="error"
                            :block="mobile"
                            :disabled="deleteConfirmationFamilyName !== family.familyName"
                            :loading="family.isDeleting"
                            @click="deleteFamily()"
                        >
                            {{ $t('permanently-delete-family') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <div v-if="family.currentUsersRole !== FamilyRole.Owner">
                <v-row>
                    <v-col>
                        <v-btn
                            color="error"
                            :block="mobile"
                            :loading="family.isLeaving"
                            @click="leaveFamily()"
                            :disabled="family.currentUsersRole == undefined"
                        >
                            {{ $t('leave-family') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
        </template>


    </fa-dialog>
    <v-snackbar
        v-model="savedSnackBarOpen"
        color="success"
    >
        {{ $t("saved-changes") }}
    </v-snackbar>
    <v-snackbar
        v-model="userRemovedSnackBarOpen"
        color="success"
    >
        {{ $t("the-user-was-successfully-removed") }}
    </v-snackbar>
    <v-snackbar
        v-model="invitationSentSnackBarOpen"
        color="success"
    >
        {{ $t("the-invitation-was-sent") }}
    </v-snackbar>
    <v-snackbar
        v-model="invitationDeletedSnackBarOpen"
        color="success"
    >
        {{ $t("the-invitation-was-deleted") }}
    </v-snackbar>
    <v-snackbar
        v-model="savingErrorSnackBarOpen"
        color="error"
    >
        {{ $t("an-error-occured-during-saving") }}
    </v-snackbar>

    <confirm-dialog ref="confirmDialog" />

</template>

<script setup lang="ts">
import { Family } from '@models/Family';
import { ref } from 'vue';
import { computed } from 'vue';
import { Ref } from 'vue';
import { useDisplay } from 'vuetify';
import { FamilyRole } from '../../types/FamilyRole';
import { useI18n } from 'vue-i18n';
import { FamilyMembership } from '../../models/FamilyMembership';
import ConfirmDialog from '/resources/js/components/ConfirmDialog.vue';
import { FamilyInvitation } from '../../models/FamilyInvitation';
import { FamilyPerson } from '@models/FamilyPerson';
import { Gender } from '@models/FamilyPerson';
import { useAuthStore } from '../../stores/authStore';
import { useFamilyStore } from '../../stores/familyStore';
import { Rules } from '../../types/ValidationRules';
import { countries, currencies } from '../../FactumLocale.ts'

const open: Ref<boolean> = ref(false);

const hasNewFamily: Ref<boolean> = computed(() => {
    return family.value?.id == null;
})

const savedSnackBarOpen: Ref<boolean> = ref(false);
const userRemovedSnackBarOpen: Ref<boolean> = ref(false);
const invitationDeletedSnackBarOpen: Ref<boolean> = ref(false);
const invitationSentSnackBarOpen: Ref<boolean> = ref(false);
const savingErrorSnackBarOpen: Ref<boolean> = ref(false);

const confirmDialog: Ref<ConfirmDialog> = ref(null);
const newInvitationForm = ref(null);
const inviteEmail: Ref<string> = ref("");
const currentView: Ref<string> = ref('main');
const { mobile } = useDisplay();

const newPerson: Ref<FamilyPerson> = ref(new FamilyPerson());

const deleteConfirmationFamilyName = ref("");

const currentRole: Ref<FamilyRole> = computed(() => {
    if (family.value?.id == null) {
        return FamilyRole.Owner;
    }

    let memberships = family.value.memberships;
    let authStore = useAuthStore();

    let membership = memberships.find(membership => {
        return membership.user?.id == authStore.user.id;
    });

    return membership?.role;
})

const canEdit: Ref<boolean> = computed(() => {
    if (currentRole.value == FamilyRole.Owner || currentRole.value == FamilyRole.Admin) {
        return true;
    }

    return false;
})

const family: Ref<Family|null> = ref(null);
const { t } = useI18n();

const roles: Ref<Array<{role: FamilyRole, title: String}>> = computed(() => {
    return [
        {
            role: FamilyRole.Owner,
            title: t('owner')
        },
        {
            role: FamilyRole.User,
            title: t('user')
        },
        {
            role: FamilyRole.Admin,
            title: t('admin')
        },
        {
            role: FamilyRole.Reader,
            title: t('reader')
        }
    ]
});

const genders: Ref<Array<{gender: Gender, title: String}>> = computed(() => {
    return [
        {
            gender: Gender.MALE,
            title: t('male')
        },
        {
            gender: Gender.FEMALE,
            title: t('female')
        },
    ]
});

const loading: Ref<boolean> = computed(() => {
    return family.value?.isDeleting || family.value?.isSaving;
});

defineExpose({
    openFamily,
    openNewFamily,
    close,
})

const emits = defineEmits([
    'didSave',
    'didSaveNewFamily'
]);

function close()
{
    open.value = false;
}

function openFamily(_family: Family)
{
    currentView.value = "main";
    deleteConfirmationFamilyName.value = "";
    family.value = _family.copy();
    family.value.getMemberships();
    family.value.getPersons();
    family.value.getInvitations();
    open.value = true;
}

function openNewFamily()
{
    currentView.value = "main";
    deleteConfirmationFamilyName.value = "";
    family.value = new Family();
    open.value = true;
}

function deleteFamily()
{
    family.value.delete().then(() => {
        let familyStore = useFamilyStore();
        familyStore.removeFamily(family.value);
        open.value = false;
    })
}

function leaveFamily()
{
    confirmDialog.value.okButtonText = t('leave');
    confirmDialog.value.cancelButtonText = t("cancel");
    confirmDialog.value.okButtonColor = "error"
    confirmDialog.value.confirm(
        t('leave-family'),
        t('do-you-really-want-to-leave-this-family')
    ).then(confirmed => {
        if (confirmed) {
            family.value.leaveFamily().then(didLeave => {
                if (didLeave) {
                    let familyStore = useFamilyStore();
                    familyStore.removeFamily(family.value);
                    open.value = false;
                }
            })
        }
    })
}

function saveMembership(membership: FamilyMembership)
{
    membership.save().then(() => {
        savedSnackBarOpen.value = true;
    }).catch(error => {
        console.error(error);
        savingErrorSnackBarOpen.value = true;
    })
}

function deleteMembership(membership: FamilyMembership)
{
    confirmDialog.value.okButtonText = "Fjern brukeren";
    confirmDialog.value.cancelButtonText = "Avbryt";
    confirmDialog.value.okButtonColor = "error"
    confirmDialog.value.confirm(
        t('remove-user'),
        t('do-you-really-want-to-remove-this-user')
    ).then(confirmed => {
        if (confirmed) {
            membership.delete().then(() => {
                let index = family.value.memberships.indexOf(membership);
                if (index > -1) {
                    family.value.memberships.splice(index, 1);
                }
                userRemovedSnackBarOpen.value = true;
            })
        }
    })
}

function saveInvitation(invitation: FamilyInvitation)
{
    invitation.save().then(() => {
        savedSnackBarOpen.value = true;
    }).catch(error => {
        console.error(error);
        savingErrorSnackBarOpen.value = true;
    })
}

function sendInvitation()
{
    newInvitationForm.value.validate().then(result => {
        if (result.valid) {
            let invitation = new FamilyInvitation();
            invitation.family = family.value;
            invitation.email = inviteEmail.value;
            invitation.role = FamilyRole.User;

            invitation.save().then(() => {
                inviteEmail.value = "";
                family.value.invitations.push(invitation);
                invitationSentSnackBarOpen.value = true;
            }).catch(error => {
                console.error(error);
                savingErrorSnackBarOpen.value = true;
            })
        }
    });
}

function deleteInvitation(invitation: FamilyInvitation)
{
    confirmDialog.value.okButtonText = t('delete');
    confirmDialog.value.cancelButtonText = t('cancel');
    confirmDialog.value.okButtonColor = "error"
    confirmDialog.value.confirm(
        t('delete-invitation'),
        t('do-you-really-want-to-delete-this-invitation') + "?"
    ).then(confirmed => {
        if (confirmed) {
            invitation.delete().then(() => {
                let index = family.value.invitations.indexOf(invitation);
                if (index > -1) {
                    family.value.invitations.splice(index, 1);
                }
                invitationDeletedSnackBarOpen.value = true;
            })
        }
    })
}

function saveNewPerson()
{
    newPerson.value.family = family.value;

    newPerson.value.save().then(result => {
        family.value.persons.push(result);
        savedSnackBarOpen.value = true;
        newPerson.value = new FamilyPerson();
    }).catch(error => {
        console.error(error);
        savingErrorSnackBarOpen.value = true;
    })
}

function savePerson(person: FamilyPerson)
{
    person.save().then(() => {
        savedSnackBarOpen.value = true;
    })
}

function deletePerson(person: FamilyPerson)
{
    person.delete().then(() => {
        let index = family.value.persons.indexOf(person);
        if (index > -1) {
            family.value.persons.splice(index, 1);
        }
        savedSnackBarOpen.value = true;
    })
}

function saveFamily()
{
    let oldId = family.value.id;
    family.value.save().then((result) => {
        const familyStore = useFamilyStore();
        familyStore.updateFamily(result);
        emits('didSave', result);
        if (oldId == null) {
            emits('didSaveNewFamily', result);
        }
        savedSnackBarOpen.value = true;

        if (oldId == null) {
            let authStore = useAuthStore();
            let familyStore = useFamilyStore();

            authStore.user.families.push(result);
            familyStore.addFamily(result);
        }
    }).catch(error => {
        console.error(error);
        savingErrorSnackBarOpen.value = true;
    })
}

</script>
